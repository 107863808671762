import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Image_BASE_URL, API_BASE_URL } from "../utils/constants"
import { CircularProgress, Box } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './HomeCarouselMobi.css';


function HomeCarouselMobi({ isMobile, isTablet=false,feedBack }) {

  const [data, setData] = useState(null);
  const [isHovered, setIsHovered] = useState(0);
  const [isIndicatorHovered, setIsIndicatorHovered] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/all_ages/public/index.php/carousel`);
        if (response.data.code === 200) {
          const items = response.data.data;
          var array = [];
          items.forEach(element => {
            var dic = {
              id: element.id,
              img: Image_BASE_URL + element.img,
              link: element.link
            }
            array.push(dic);
          });
        }
        setData(array);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  if (!data) {
    return <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }}
    >
      <CircularProgress color="primary.500" size="30px" isIndeterminate />
    </div>;
  }


  const handleItemClick = (item) => {

    feedBack(item.id);
    window.open(item.link, '_blank');
    //navigate(`/goods/${id}`);
  };

  if (isMobile) {
    let customRenderIndicator = (clickHandler, isSelected, index, label) => {

      const defStyle = {
        //marginTop: '10px',
        //marginLeft: '12px',
        margin: '-16px 7px',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: "#d9d9d9",
        cursor: "pointer",
        display: 'inline-block'
      };
      const style = isSelected
        ? { ...defStyle, backgroundColor: "#4599e7" }
        : { ...defStyle };
      return (
        <Box style={style} onClick={clickHandler} value={index} key={index} alt=''></Box>
      );
    };
    let divStyle = { width: '100%', height: 'calc(100vw / 360 * 131)' }
    let imgStyle = { width: '100%', objectFit: 'cover', height: 'calc(100vw / 360 * 115)' }

    if(isTablet){
      customRenderIndicator = (clickHandler, isSelected, index, label) => {

        const defStyle = {
          //marginTop: '10px',
          //marginLeft: '12px',
          margin: '-16px 7px',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: "#d9d9d9",
          cursor: "pointer",
          display: 'inline-block'
        };
        const style = isSelected
          ? { ...defStyle, backgroundColor: "#4599e7" }
          : { ...defStyle };
        return (
          <Box style={style} onClick={clickHandler} value={index} key={index} alt=''></Box>
        );
      };

      divStyle = { width: '100%', height: 'calc(100vw / 820 * 292)' }
      imgStyle = { width: '100%', objectFit: 'cover', height: 'calc(100vw / 820 * 262)' }
    }

    return (
      <div>
        <Carousel showThumbs={false}
          showArrows={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          showStatus={false}
          renderIndicator={customRenderIndicator}
        //style={{ width: '100%', height: '100%' }}
        >
          {data.map((image, index) => (
            <div key={index} onClick={() => handleItemClick(image)} style={divStyle}>
              <img src={image.img} alt={index} style={imgStyle} />
            </div>
          ))}
        </Carousel>
      </div>
    );
  } else {
    const customRenderIndicator = (clickHandler, isSelected, index, label) => {

      //const Color = '#6eb7ee';

      const defStyle = {
        margin: 'calc(max(100vw, 1280px) / 1920 * 31) 8px',
        width: '8px',
        height: '8px',
        borderRadius: '4px',
        backgroundColor: isIndicatorHovered === index + 1 ? "#6eb7ee" : "#d9d9d9",
        cursor: "pointer",
        display: 'inline-block'
      };
      const style = isSelected
        ? { ...defStyle, backgroundColor: "#4599e7" }
        : { ...defStyle };
      return (
        <Box style={style} 
        onClick={clickHandler} 
        key={index} 
        onMouseEnter={() => {
          setIsIndicatorHovered(index + 1);
        }}
        onMouseLeave={() => {
          setIsIndicatorHovered(0);
        }}
        >
        </Box>
      );
    };

    return (
      <div className="horizontal-carousel-container">
        <style>{`
        .horizontal-carousel-container .carousel .control-arrow {
          height: calc(max(57vw, 729.6px) / 1100 * 352); 
          top: 0;
        }
      `}</style>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          emulateTouch
          infiniteLoop={true}
          autoPlay
          interval={3000} // 轮播间隔时间（单位：毫秒）
          centerMode // 居中显示
          centerSlidePercentage={60} // 每个图像的宽度百分比
          renderIndicator={customRenderIndicator}
        >
          {data.map((image, index) => (
            <Box key={index}
              onClick={() => handleItemClick(image)}
              onMouseEnter={() => {
                setIsHovered(index + 1);
              }}
              onMouseLeave={() => {
                setIsHovered(0);
              }}
              style={{ cursor: "pointer", width: '100%', height: 'calc(max(57vw, 729.6px) / 1100 * 423)', paddingLeft: 'max(1.5vw, 19.2px)', paddingRight: 'max(1.5vw, 19.2px)', opacity: isHovered === (index + 1) ? '0.6' : '1' }}>
              <img src={image.img} alt={index} style={{ objectFit: 'cover', height: 'calc(max(57vw, 729.6px) / 1100 * 352)', borderRadius: '16px', }} />
            </Box>
          ))}
        </Carousel>
      </div>
    );
  }
}

export default HomeCarouselMobi;