import React, { useRef, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './Goods.css';
import '../Home/Home.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Stack, Button, CircularProgress, Tag, Box, Flex, Image, Text } from "@chakra-ui/react";
import { motion } from 'framer-motion';
//import { FaArrowLeft } from 'react-icons/fa';
import { Image_BASE_URL, API_BASE_URL, File_BASE_URL, AG_LINK_BASE_URL } from "../../utils/constants"
import HeaderMobi from '../../components/Header';
import LeftArrow from '../../assets/images/leftArrow.png';
import leftArrowBlue from '../../assets/images/leftArrowBlue.png';
import CPreArrow from '../../assets/images/carouselPreArrow.png';
import CNextArrow from '../../assets/images/carouselNextArrow.png';
import DownloadIcon from '../../assets/images/downloadIcon.png';
import Footer from '../../components/Footer';

const Header = ({ isMobile, isTablet = false }) => {
  // const location = useLocation();
  // const [currentPath, setCurrentPath] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // useEffect(() => {
  //   setCurrentPath(location.pathname);
  // }, [location]);

  if (isMobile) {
    return <header className={isTablet ? "sticky-header-tb" : "sticky-header"}>
      <Link to="../" style={{ textDecoration: 'none' }}>
        <Button leftIcon={<img src={LeftArrow} alt="Left Arrow" width="16px" height="16px" />} variant="link" height={'100%'} fontSize={'14px'}>
          首页
        </Button>
      </Link>
    </header>
  } else {
    return <header className="sticky-header-pc">
      <Link to="../" style={{ textDecoration: 'none' }}>
        <Button leftIcon={
          <img src={isHovered ? leftArrowBlue : LeftArrow} alt="Left Arrow" width="16px" height="16px" mt="24px" />
        }
          variant="link"
          height={'100%'}
          fontSize={'14px'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          _hover={{ color: 'primary.500' }}
        >
          首页
        </Button>
      </Link>
    </header>
  }
}

const linkCount = (id, type) => {
  const fetchData = async () => {
    try {
      const url = `${API_BASE_URL}/all_ages/public/index.php/hitcount?id=${id}&type=${type}`;
      //console.log(url);
      const response = await axios.get(url);
      if (response.data.code === 200) {
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
};

const SubHead = ({ headName, isMobile, isTablet = false }) => {
  if (isMobile) {
    return <Flex className='goods-sub-head' style={isTablet ? { marginTop: '32px' } : {}} height={'45px'} direction='column'>
      <p style={{ lineHeight: '30px' }}>{headName}</p>
      <div style={{ backgroundColor: '#FDD132', width: '24px', height: '3px', marginTop: '12px' }}></div>
    </Flex>
  } else {
    return <Flex className='goods-sub-head' height={'45px'} direction='column'>
      <p style={{ lineHeight: '30px' }}>{headName}</p>
      <div style={{ backgroundColor: '#FDD132', width: '48px', height: '3px', marginTop: '12px' }}></div>
    </Flex>
  }
}

const FloatingWindow = ({ item }) => {

  return (
    <Box
      w='283px'
      h='162px'
      position="fixed"
      right="0"
      top="calc(max(57vw, 729.6px) / 1100 * 732)"
      transform="translateY(-50%)"
      backgroundColor="white"
      padding="21px"
      borderRadius="8px 0 0 8px"
      boxShadow="0 2px 5px rgba(0, 0, 0, 0.2)"
      zIndex="999"
    >
      <Flex alignItems="center" marginBottom="16px">
        <img src={Image_BASE_URL + item.imgs[0]} alt="" style={{ width: '106px', height: '70px', objectFit: 'cover' }} />

        <Box marginLeft="16px" fontSize={'16px'} width='119px' height='70px' lineHeight={'23px'}>
          <h2 style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{item.title}</h2>
        </Box>
      </Flex>
      <motion.div whileHover={{ opacity: 0.6 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}>
        <Button
          w="100%"
          h={'34px'}
          bg={'linkBg.500'}
          borderRadius={'17px'}
          style={{
            _focus: { boxShadow: 'none' },
            _active: { boxShadow: 'none' },
          }}
          _hover={{}}
          onClick={() => {
            linkCount(item.id, 'dlsite');
            window.open(AG_LINK_BASE_URL + '?rj=' + item.rj + '&id=' + item.id, '_blank');
          }}>购买作品</Button>
      </motion.div>
    </Box>
  );
}

const CarouselExample = ({ imgs, isMobile }) => {

  const imageStyle = {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  };
  const imageStylePc = {
    objectFit: 'contain',
    width: 'calc(max(57vw, 729.6px) / 1100 * 572)',
    height: 'calc(max(57vw, 729.6px) / 1100 * 427)'
  };
  if (isMobile) {
    return (<div className='carousel-size'>
      <style>{`
        .carousel-size .carousel .thumbs-wrapper {
          background-color : #f6f7fa;
          margin: 0;
          padding:calc(100vw / 360 * 12);
          height: calc(100vw / 360 * 78)
        }
        .carousel-size .carousel .thumbs {
          height: calc(100vw / 360 * 72);
        }
        .carousel-size .carousel .thumb {
          width: calc(100vw / 360 * 72) !important;
          height: calc(100vw / 360 * 54) !important;
          margin-right: calc(100vw / 360 * 8);
          border: 0;
          padding: 0;
        }
        .carousel-size .carousel .thumb.selected {
          border: 3px solid #4599e7; 
        }
        .carousel-size .carousel .thumb img {
          height:100%;
          width:100%;
          vertical-align: middle; 
          object-fit: contain;
        }
      `}</style>
      <Carousel
        renderThumbs={() => {
          let images = imgs.map((url, index) => (
            <img key={index} alt={index} src={url} style={{objectFit:'contain',verticalAlign:'middle'}}>
            </img>
          ))
          return images
        }}
        showIndicators={false}
        showStatus={false}
      >
        {imgs.map((url, index) => (
          <div className='carousel-size-img' key={index}>
            <img style={imageStyle} src={url} alt=''></img>
          </div>
        ))}
      </Carousel>
    </div>);
  } else {
    return (<div className='carousel-size-pc'>
      <style>{`
        .carousel-size-pc .carousel .thumbs-wrapper {
          background-color : #f6f7fa;
          margin: 0;
          padding-top:calc(max(57vw, 729.6px) / 1100 * 12);
          padding-bottom:calc(max(57vw, 729.6px) / 1100 * 12);
          height: calc(max(57vw, 729.6px) / 1100 * 99)
        }
        .carousel-size-pc .carousel .thumbs {
          height: calc(max(57vw, 729.6px) / 1100 * 75);
        }
        .carousel-size-pc .carousel .thumb {
          width: calc(max(57vw, 729.6px) / 1100 * 100) !important;
          height: calc(max(57vw, 729.6px) / 1100 * 75) !important;
          margin-right: calc(max(57vw, 729.6px) / 1100 * 8);
          border: 0;
          padding: 0;
        }
        .carousel-size-pc .carousel .thumb.selected {
          border: 3px solid #4599e7; 
        }
        .carousel-size-pc .carousel .thumb img {
          height:100%;
          width:100%;
          vertical-align: middle; 
          object-fit: contain;
        }
      `}</style>
      <Carousel
        renderThumbs={() => {
          let images = imgs.map((url, index) => (
            <img key={index} alt={index} src={url} style={{objectFit:'contain',verticalAlign:'middle'}}>
            </img>
          ))
          return images
        }}

        showIndicators={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button onClick={onClickHandler} className="custom-arrow prev-arrow control-pre">
              <img src={CPreArrow} alt='pre' style={{ width: 'calc(max(57vw, 729.6px) / 1100 * 21.5)', height: 'calc(max(57vw, 729.6px) / 1100 * 33.5)' }}></img>
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button onClick={onClickHandler} className="custom-arrow next-arrow control-next">
              <img src={CNextArrow} alt='next' style={{ width: 'calc(max(57vw, 729.6px) / 1100 * 21.5)', height: 'calc(max(57vw, 729.6px) / 1100 * 33.5)' }}></img>
            </button>
          )
        }
      >
        {imgs.map((url, index) => (
          <div style={{ width: 'calc(max(57vw, 729.6px) / 1100 * 705)', height: 'calc(max(57vw, 729.6px) / 1100 * 427)' }} key={index}>
            <img style={imageStylePc} src={url} alt=''></img>
          </div>
        ))}
      </Carousel>
    </div>);
  }

};

const GoodsInfo = ({ goodsInfo, isMobile, isTablet = false, part }) => {

  var array1 = [];
  var array2 = [];
  goodsInfo.details.forEach(element => {
    if (element.title === '文件形式' ||
      element.title === '支持的语言' ||
      element.title === '分类' ||
      element.title === '文件容量') {
      array2.push(element);
    } else {
      array1.push(element);
    }
  });

  if (isMobile) {
    return <div style={{ marginTop: '40px' }}>

      <div className='goods-info-box'>
        <Stack direction="column" >
          <div style={{ width: '1px', height: '8px' }}></div>
          <Stack direction="row" spacing={'12px'}>
            <p className='goods-info-box-title'>{goodsInfo.head[0]} </p>
            <p className='goods-info-box-detail'>{goodsInfo.head[1]}</p>
          </Stack>
          {array1.map((item, index) => (
            <Stack direction="row" key={index}>
              <p className='goods-info-box-title'>{item.title} </p>
              <NormalTag details={item.detail} />
            </Stack>
          ))}
        </Stack>
        <div style={{ width: 1, height: '20px' }}></div>
      </div>

      <div className='goods-info-box' style={{ backgroundColor: 'white', marginTop: isTablet ? '32px' : '20px' }}>
        <Stack direction="column" spacing={'12px'}>
          {array2.map((item, index) => (
            <Stack direction="row" key={index}>
              <p className='goods-info-box-title'>{item.title} </p>
              {item.title === '分类' ? <ClassificationTag details={item.detail} /> :
                <NormalTag details={item.detail} />
              }
            </Stack>
          ))}
        </Stack>
      </div>
    </div>
  } else {
    if (part === 1) {
      return <div style={{ marginTop: '28px' }}>

        <div className='goods-info-box'>
          <Stack direction="column" >
            <div style={{ width: 1, height: '4px' }}></div>
            <Stack direction="row" spacing={'12px'}>
              <p className='goods-info-box-title'>{goodsInfo.head[0]} </p>
              <p className='goods-info-box-detail'>{goodsInfo.head[1]}</p>
            </Stack>
            {array1.map((item, index) => (
              <Stack direction="row" key={index}>
                <p className='goods-info-box-title'>{item.title} </p>
                <NormalTag details={item.detail} />
              </Stack>
            ))}
          </Stack>
          <div style={{ width: 1, height: '16px' }}></div>
        </div>
      </div>
    } else {
      return <div style={{ marginTop: '48px' }}>
        <div className='goods-info-box' style={{ backgroundColor: 'white' }}>
          <Stack direction="column" >
            {array2.map((item, index) => (
              <Stack direction="row" key={index} spacing={'12px'}>
                <p className='goods-info-box-title' style={{ width: '17%' }}>{item.title} </p>
                {item.title === '分类' ? <ClassificationTag details={item.detail} /> :
                  <NormalTag details={item.detail} />
                }
              </Stack>
            ))}
          </Stack>
        </div>
      </div>
    }
  }

}

const ClassificationTag = ({ details }) => {
  return <Stack className='goods-info-box-detail' direction="row" flexWrap="wrap" spacing={2}>
    {details.map((item, index) => (
      <Tag
        key={index}
        borderRadius="full"
        fontWeight="400"
        bg="#f6f7fa"
        color="primary.500"
        fontSize="14px"
        px={3}
        py={1}
      >{item}
      </Tag>
    ))}
  </Stack>
}

const NormalTag = ({ details }) => {
  return <Stack className='goods-info-box-detail' direction="row" flexWrap="wrap" spacing={2}>
    {details.map((item, index) => (
      <p key={index}>{item} </p>
    ))}
  </Stack>
}

const Chobit = ({ url, isMobile, isTablet = false }) => {

  const videoContainerRef = useRef(null);

  useEffect(() => {
    const calculateAspectRatio = () => {
      const videoContainer = videoContainerRef.current;
      if (videoContainer) {
        const video = videoContainer.querySelector('iframe, video');
        if (video) {
          const aspectRatio = (video.offsetHeight / video.offsetWidth) * 100;
          videoContainer.style.paddingBottom = `${aspectRatio}%`;
        }
      }
    };

    calculateAspectRatio();

    window.addEventListener('resize', calculateAspectRatio);

    return () => {
      window.removeEventListener('resize', calculateAspectRatio);
    };
  }, []);

  return <div style={{ marginTop: isTablet ? '64px' : '40px' }}>
    <SubHead headName={'预览'} isMobile={isMobile} isTablet={isTablet} />
    <div className="video-container" id="iframeContainer" ref={videoContainerRef}>

      <iframe
        id="myIframe"
        src={url}
        title="Embedded Video"
        frameBorder="0"
        allowFullScreen
        //ref={iframeRef}
        security="restricted"
        sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts"
      ></iframe>
    </div>
  </div>
}

const GoodsDetail = ({ details, isMobile, isTablet = false }) => {
  if (isMobile) {
    return <div style={{ marginTop: isTablet ? '64px' : '40px', paddingBottom: isTablet ? '116px' : '80px' }}>
      <SubHead headName={'作品内容'} isMobile={isMobile} />
      {
        details.map((item, index) => (
          (item.title || item.detail.length > 0) && <GoodsDetailRow item={item} key={index} isMobile={isMobile} isTablet={isTablet} />
        ))
      }
    </div>
  } else {
    return <div style={{ marginTop: '60px', paddingBottom: '80px' }}>
      <SubHead headName={'作品内容'} isMobile={isMobile} />
      {
        details.map((item, index) => (
          (item.title || item.detail.length > 0) && <GoodsDetailRow item={item} key={index} isMobile={isMobile} />
        ))
      }
    </div>
  }

}

const GoodsDetailRow = ({ item, isMobile, isTablet = false }) => {
  //console.log(item)
  return <div style={{ marginTop: isMobile && !isTablet ? '32px' : '40px' }}>
    <Box
      marginBottom={isTablet ? '44px' : '28px'}
      as="hr"
      borderTop="1px dashed #C0C5D6"  // 这里设置虚线的样式，可以调整颜色和样式
    />
    <>
      {item.title && <p className='goods-detail-title' style={isTablet ? { marginBottom: '16px' } : {}}>{item.title}</p>}
    </>
    {
      item.detail.map((e, index) => (
        <div key={index}>{
          <>
            {'text' in e && <GoodsDetailRowText text={e.text} />}
            {'img' in e && <img src={Image_BASE_URL + e.img} alt=''></img>}
          </>
        }
        </div>
      ))
    }

  </div>
}

const GoodsDetailRowText = ({ text }) => {

  const urlRegex = /(https?:\/\/[^\s]+)/g; // 正则表达式用于匹配 URL

  // 使用正则表达式将换行符 \n 替换为 <br> 标签
  const replacedText = text.replace(/\n/g, "\n");

  // 将文本拆分成链接和非链接部分
  const parts = replacedText.split(urlRegex);

  // 使用 map 方法处理每个部分，并根据类型渲染不同的 JSX
  const jsxContent = parts.map((part, index) => {
    if (urlRegex.test(part)) {
      // 如果是链接部分，返回一个 <a> 标签
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "#4599e7" }}>
          {part}
        </a>
      );
    } else {
      // 如果是非链接部分，直接返回文本
      return <span key={index}>{part}</span>;
    }
  });

  return (
    <div className='goods-detail-text' style={{ whiteSpace: "pre-line" }}>
      {jsxContent}
    </div>
  );
}

const Download = ({ item, isMobile, id, isfree, isTablet = false }) => {

  const handleDownload = () => {
    linkCount(id, 'download');
    const downloadUrl = File_BASE_URL + item.link;
    window.open(downloadUrl, '_blank');
  };

  if (isMobile) {
    return (
      <Box
        mt={isTablet ? '32px' : '20px'}
        width='100%'
        height='43px'
        border="1px solid #C0C5D6"
        borderRadius="8px"
        bg="white"
        display="inline-block"
        overflow='hidden'
      >
        <Flex alignItems="center" height='100%'>
          <Box width={isTablet ? '12.7%' : '18.67%'} height='100%' lineHeight='43px' bg="gray.200" textAlign="center" fontSize='14px'>
            <Text color="black">{isfree === 1 ? '完全版' : '体验版'}</Text>
          </Box>
          <Box bg="white" height='100%' lineHeight='43px' ml="10px" fontSize='14px'>
            <Text color='#929BBA'>{isfree === 1 ? '完全版仅限PC端下载' : '体验版仅限PC端下载'}</Text>
          </Box>
        </Flex>
      </Box>
    );
  } else {
    return <Flex alignItems="center" mt={'16px'}>
      <motion.div whileHover={{ opacity: 0.6 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}>
        <Button colorScheme="primary" fontSize='16px' h='52px' borderRadius='26px' onClick={handleDownload} _hover={{}}>
          <Image src={DownloadIcon} boxSize="24px" mr="2" />
          {isfree === 1 ? '下载完全版' : '下载体验版'}
        </Button>
      </motion.div>
      <Text ml="10px" fontSize='14px'>{item.text}</Text>
    </Flex>
  }
}

const Goods = () => {

  const { id } = useParams();
  const [data, setData] = useState(null);
  let isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  if(isMobileDevice === false){
    isMobileDevice = /tablet|ipad|android/.test(window.navigator.userAgent.toLowerCase());
    if(isMobileDevice === false){
      isMobileDevice = /iPad|Macintosh/i.test(navigator.userAgent) && 'ontouchend' in document;
    }
  }
  const [isTablet, setIsTablet] = useState(false);

  const checkTablet = () => {
    const tabletWidth = 768;
    const screenWidth = window.innerWidth;
    setIsTablet(screenWidth >= tabletWidth);
  };

  useEffect(() => {
    checkTablet();
    window.addEventListener('resize', checkTablet);

    return () => {
      window.removeEventListener('resize', checkTablet);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/all_ages/public/index.php/goodsdetail?id=${id}`);
        if (response.data.code === 200) {
          const item = response.data.data;
          setData(item);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0); // 页面加载时滚动到顶部
  }, []);


  if (!data) {
    return <div className="app-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <CircularProgress color="primary.500" size="30px" isIndeterminate />
      </div>
    </div>;
  }

  let infoArr = data.goods_info
  let imgs = []
  data.imgs.forEach((element) => {
    imgs.push(Image_BASE_URL + element)
  });
  let imgs_thumb = []
  data.imgs_thumb.forEach(element => {
    imgs_thumb.push(Image_BASE_URL + element)
  });
  let chobitUrl = ''
  if ('chobit' in data) {
    if (data['chobit'].length > 0) {
      chobitUrl = data['chobit'][0]
    }
  }
  let goodsDetails = data.goods_detail
  let download = data.download;
  let detailPadding = isTablet ? 'calc(100vw / 820 * 28)' : '14px';

  if (isMobileDevice) {
    return (
      <div className="app-container">
        <HeaderMobi isMobile={isMobileDevice} isTablet={isTablet}></HeaderMobi>
        <Header isMobile={isMobileDevice} isTablet={isTablet} />
        <CarouselExample imgs={imgs} isMobile={isMobileDevice} />
        <div className='goods-content' style={{ width: '100%', paddingLeft: detailPadding, paddingRight: detailPadding }}>
          <p className={isTablet ? 'goods-title-tb' : 'goods-title'}>{data.title}</p>
          <Button
            w="calc(100%)"
            h={'47px'}
            bg={'linkBg.500'}
            fontSize={isTablet ? '20px' : '16px'}
            borderRadius={'23.5px'}
            style={{
              _focus: { boxShadow: 'none' },
              _active: { boxShadow: 'none' },
            }}
            onClick={() => {
              linkCount(data.id, 'dlsite');
              window.open(AG_LINK_BASE_URL + '?rj=' + data.rj + '&id=' + data.id, '_blank');
            }}>购买作品</Button>
          <GoodsInfo goodsInfo={infoArr} isMobile={isMobileDevice} isTablet={isTablet} />
          {download.link && <Download item={download} isMobile={isMobileDevice} id={data.id} isfree={data.is_free} isTablet={isTablet} />}
          {chobitUrl.length > 0 && <Chobit url={chobitUrl} isMobile={isMobileDevice} isTablet={isTablet} />}
          <GoodsDetail details={goodsDetails} isMobile={isMobileDevice} isTablet={isTablet} />
        </div>
        <Footer isMobile={true} />
      </div>

    );
  } else {
    return (
      <div className="app-container">
        <HeaderMobi isMobile={isMobileDevice}></HeaderMobi>
        <FloatingWindow item={data} />
        <div className='app-container-pc'>
          <Header isMobile={isMobileDevice} />
          <Flex justifyContent="space-between" width="100%">
            <Box width="64.09%">
              <CarouselExample imgs={imgs} isMobile={isMobileDevice} />
              <GoodsInfo goodsInfo={infoArr} isMobile={isMobileDevice} part={2} />
              {download.link && <Download item={download} isMobile={isMobileDevice} id={data.id} isfree={data.is_free} />}
              {chobitUrl.length > 0 && <Chobit url={chobitUrl} isMobile={isMobileDevice} />}
              <GoodsDetail details={goodsDetails} isMobile={isMobileDevice} />
            </Box>
            <Box width="32.73%">
              <p className='goods-title-pc'>{data.title}</p>
              <GoodsInfo goodsInfo={infoArr} isMobile={isMobileDevice} part={1} />
              <motion.div whileHover={{ opacity: 0.6 }}
                transition={{ duration: 0.3, ease: 'easeOut' }}>
                <Button
                  marginTop='28px'
                  w="100%"
                  h={'47px'}
                  bg={'linkBg.500'}
                  borderRadius={'23.5px'}
                  style={{
                    _focus: { boxShadow: 'none' },
                    _active: { boxShadow: 'none' },
                  }}
                  _hover={{}}
                  onClick={() => {
                    linkCount(data.id, 'dlsite');
                    window.open(AG_LINK_BASE_URL + '?rj=' + data.rj + '&id=' + data.id, '_blank');
                  }}>购买作品
                </Button>
              </motion.div>
            </Box>
          </Flex>
        </div>
        <Footer isMobile={isMobileDevice} />
      </div>

    );
  }
};

export default Goods;