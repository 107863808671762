// Header.js
import React, { useState } from 'react';
//import HeaderImage from '../assets/images/homeHeader.png';
//import LogoIcon from '../assets/images/logo.png';
//import LogoIconHover from '../assets/images/logoHover￼.png';
import LogoIcon1 from '../assets/images/logo_primary.svg';
import LogoIconHover1 from '../assets/images/logo_primary_hover.svg';
import { Flex, Box } from '@chakra-ui/react';
import { API_BASE_URL } from "../utils/constants";


const HeaderMobi = ({ isMobile, isTablet = false }) => {

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  if (isMobile) {
    let boxHeight = '52px';
    let imgHeight = '25px';
    if (isTablet) {
      boxHeight = '68px';
      imgHeight = '32px';
    }
    return <Box
      backgroundColor={'primary.500'}
      width='100vw'
      height={boxHeight}
      style={{
        position: 'sticky',
        top: '0',
        zIndex: '10'
      }}
      onClick={() => {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // }); 
        window.location.href = API_BASE_URL;
      }}
    >
      <Flex align="center" justify="center" height="100%">
        <img style={{ height: imgHeight }} src={LogoIcon1} alt='' />
      </Flex>
    </Box>
  } else {
    return <Box
      backgroundColor={'primary.500'}
      width='max(100vw, 1280px)'
      height={'64px'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'sticky',
        top: '0',
        zIndex: '10'
      }}
      onClick={() => {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // }); 
        window.location.href = API_BASE_URL;
      }}
    >
      <Flex align="center" justify="center" height="100%">
        <img style={{ height: '36px' }} src={isHovered ? LogoIconHover1 : LogoIcon1} alt='' />
      </Flex>
    </Box>
  }

}

export default HeaderMobi;
