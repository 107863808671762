import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import './Home.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';
import { Input, Flex, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { Image_BASE_URL, API_BASE_URL } from "../../utils/constants";
import HomeCarouselMobi from '../../components/HomeCarouselMobi';
import ClassificationBarMobi from '../../components/ClassificationBar/ClassificationBarMobi';
import HomeTagBarMobi from '../../components/tagBar/HomeTagBarMobi';
import ListPage from '../../components/listPage/ListPage';
import HeaderMobi from '../../components/Header';
import SearchIcon from '../../assets/images/search.png';
import Footer from '../../components/Footer';
import Goods from '../Goods/Goods';


const SearchBar = ({ isMobile, isTablet = false, feedBack }) => {

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = () => {
    feedBack(inputValue);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      feedBack(inputValue);
    }
  };

  if (isMobile) {
    return (
      <Flex spacing="4" marginTop={isTablet ? "40px" : "32px"} mx={isTablet ? "calc(100vw / 820 * 27)" : "14px"}>
        <InputGroup>
          <InputLeftAddon
            height='48px'
            pointerEvents="none"
            bgColor="gray.100"
            borderRadius="24px 0 0 24px"
            px="2"
            children={<img src={SearchIcon} alt="Search Icon" style={{ width: '20px', height: '20px', marginLeft: '10px' }} />} // 使用自定义图片作为 InputLeftAddon 的子元素
          />
          <Input
            height='48px'
            fontSize='13px'
            colorScheme='primary'
            placeholder='搜索作品'
            _placeholder={{ color: 'primary' }}
            borderRadius="0 24px 24px 0"
            bgColor="gray.100"
            style={{ paddingLeft: '0px' }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
        </InputGroup>
      </Flex>
    );
  } else {
    return (
      <Flex spacing="0" >
        <InputGroup>
          <InputLeftAddon
            height='48px'
            pointerEvents="none"
            bgColor="gray.100"
            borderRadius="24px 0 0 24px"
            children={<img src={SearchIcon} alt="Search Icon" style={{ width: '20px', height: '20px', marginLeft: '10px' }} />} // 使用自定义图片作为 InputLeftAddon 的子元素
          />
          <Input
            height='48px'
            fontSize='15px'
            colorScheme='primary'
            placeholder='搜索作品'
            _placeholder={{ color: 'primary' }}
            borderRadius="0 24px 24px 0"
            bgColor="gray.100"
            style={{ paddingLeft: '0px' }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
          />
        </InputGroup>
      </Flex>
    )
  }

};

const linkCount = (id, type) => {
  //console.log('linkcount');
  const fetchData = async () => {
    try {
      const url = `${API_BASE_URL}/all_ages/public/index.php/hitcount?id=${id}&type=${type}`;
      //console.log(url);
      const response = await axios.get(url);
      if (response.data.code === 200) {
        //console.log('countSuccess');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
};

const Home = () => {

  const [type, setType] = useState(0);
  const [tag, setTag] = useState('');
  const [inputValue, setInputValue] = useState('');
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isTablet, setIsTablet] = useState(false);

  const checkTablet = () => {
    const tabletWidth = 768;
    const screenWidth = window.innerWidth;
    setIsTablet(screenWidth >= tabletWidth);
  };

  useEffect(() => {
    checkTablet();
    window.addEventListener('resize', checkTablet);

    return () => {
      window.removeEventListener('resize', checkTablet);
    };
  }, []);

  let isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  //console.log(window.navigator.userAgent);
  //console.log(window.navigator.maxTouchPoints);
  if(isMobileDevice === false){
    isMobileDevice = /tablet|ipad|android/.test(window.navigator.userAgent.toLowerCase());
    if(isMobileDevice === false){
      isMobileDevice = /iPad|Macintosh/i.test(navigator.userAgent) && 'ontouchend' in document;
    }
  }
  const hideStyle = {
    display: 'none'
  }
  useEffect(() => {
    if (!location.pathname.includes('/goods')) {
      window.scrollTo(0, scrollPosition);
    }
  }, [location.pathname]);

  if (isMobileDevice) {
    return (
      <div>
        <Outlet />
        <div style={location.pathname.includes('/goods') ? hideStyle : {}}>
          <HeaderMobi isMobile={true} isTablet={isTablet} />
          <HomeCarouselMobi isMobile={true} isTablet={isTablet} feedBack={(id) => {
            linkCount(id, 'carousel');
          }} />
          <SearchBar isMobile={true} isTablet={isTablet} feedBack={(inputValue) => {
            setInputValue(inputValue);
          }} />
          <ClassificationBarMobi isMobile={true} isTablet={isTablet} feedBack={(selectedValues) => {
            setType(selectedValues);
            setInputValue('');
          }} />
          <HomeTagBarMobi isMobile={true} isTablet={isTablet} feedBack={(selectedValues) => {
            setTag(selectedValues);
            setInputValue('');
          }} />

          <ListPage type={type} tag={tag} input={inputValue} isMobile={true} isTablet={isTablet} handleNavigateToGoods={(id) => {
            linkCount(id, 'pv');
            setScrollPosition(window.scrollY);
          }} />
          <Footer isMobile={true} />
        </div>
      </div>);
  } else {
    return (
      <div style={{minWidth:'1280px'}}>
        <Outlet />
        <div style={location.pathname.includes('/goods') ? hideStyle : {}}>
          <HeaderMobi isMobile={false} />
          <HomeCarouselMobi isMobile={false} feedBack={(id) => {
            linkCount(id, 'carousel');
            //setScrollPosition(window.scrollY);
          }} />
          <Flex width='max(57vw, 729.6px)' margin='auto' flexDirection="row" overflow={'visible'}>
            <Flex width='calc(max(57vw, 729.6px) / 1100 * 240)' flexDirection="column" spacing={0} padding={0}>
              <SearchBar isMobile={false} feedBack={(inputValue) => {
                setInputValue(inputValue);
              }} />
              <ClassificationBarMobi isMobile={false} feedBack={(selectedValues) => {
                setType(selectedValues);
                setInputValue('');
              }} />
              <HomeTagBarMobi isMobile={false} feedBack={(selectedValues) => {
                setTag(selectedValues);
                setInputValue('');
              }} />
            </Flex>
            <div style={{ width: 'calc(max(57vw, 729.6px) / 1100 * 820)', overflow: 'visible', marginLeft: 'calc(max(57vw, 729.6px) / 1100 * 40)' }}>
              <ListPage type={type} tag={tag} input={inputValue} isMobile={false} handleNavigateToGoods={(id) => {
                linkCount(id, 'pv');
                setScrollPosition(window.scrollY);
              }} />
            </div>
          </Flex>
          <Footer isMobile={false} />
        </div>
      </div>

    )
  }
}

export default Home;