import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Image_BASE_URL, API_BASE_URL } from "../../utils/constants";
import { Stack, Box, CircularProgress, Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';
import './ListPage.css'
import GoodsType1 from '../../assets/images/goodsType1.png';
import GoodsType2 from '../../assets/images/goodsType2.png';
import GoodsType3 from '../../assets/images/goodsType3.png';

const ListPage = ({ type, tag, input, isMobile, isTablet = false, handleNavigateToGoods }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const fetchData = async () => {
        try {
            setLoading(true);
            let url = `${API_BASE_URL}/all_ages/public/index.php/goodslist?page=${page}&type=${type}&tag=${tag}`;
            if (input.length > 0) {
                url = `${API_BASE_URL}/all_ages/public/index.php/search?page=${page}&search=${input}`;
            }
            //console.log(url);
            const response = await axios.get(url);
            if (response.data.code === 200) {
                const newData = response.data.data;
                if (page === 1) {
                    // 如果当前页数是第一页，则重置data数组
                    setData(newData);
                } else {
                    // 如果不是第一页，则将新数据追加到data数组中
                    setData((prevData) => [...prevData, ...newData]);
                }

                if (newData.length > 0) {
                    // 如果有新数据返回，则增加当前页数
                    setPage((prevPage) => prevPage + 1);
                } else {
                    // 如果没有新数据返回，则设置 hasMore 为 false，表示没有更多数据可加载
                    setHasMore(false);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        setHasMore(true);
        if (page === 1) {
            fetchData();
        } else {
            setPage(1);
            setData([]);
        }
    }, [type, tag, input]);

    useEffect(() => {
        if (page === 1) {
            fetchData();
        }
    }, [page]);

    if (isMobile) {
        return (
            <InfiniteScroll
                dataLength={data.length}
                next={fetchData}
                hasMore={hasMore}
                style={{ marginTop: isTablet ? '32px' : '24px' }}
            >
                <div className='detail' style={isTablet ? { paddingLeft: 'calc(100vw / 820 * 27)', paddingRight: 'calc(100vw / 820 * 27)' } : {}}>
                    <Stack direction={'row'} flexWrap="wrap" spacing={isTablet ? "calc(100vw / 820 * 28)" : "calc(100vw / 360 * 14)"}>
                        {data.map(goods => (
                            <Link key={goods.id} to={`/goods/${goods.id}`} onClick={() => {
                                handleNavigateToGoods(goods.id);
                            }}>
                                <GoodsCardMobi item={goods} isMobile={isMobile} isTablet={isTablet}></GoodsCardMobi>
                            </Link>
                        ))}
                    </Stack>
                    {loading && <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                        }}
                    >
                        <CircularProgress color="primary.500" size="30px" isIndeterminate />
                    </div>}
                    <Box width={'100%'} height={'80px'}></Box>
                </div>
            </InfiniteScroll>
        );
    } else {
        return (
            <InfiniteScroll
                dataLength={data.length}
                next={fetchData}
                hasMore={hasMore}
                style={{ overflow: 'visible' }}
            >
                <div className='detail-pc'>
                    <Stack direction={'row'} flexWrap="wrap" spacing={"calc(max(57vw, 729.6px) / 1100 * 27.5)"}>
                        {data.map(goods => (
                            <Link key={goods.id} to={`/goods/${goods.id}`} onClick={() => {
                                handleNavigateToGoods(goods.id);
                            }}>
                                <GoodsCardMobi item={goods} isMobile={isMobile}></GoodsCardMobi>
                            </Link>
                        ))}
                    </Stack>
                    {loading && <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                        }}
                    >
                        <CircularProgress color="primary.500" size="30px" isIndeterminate />
                    </div>}
                    <Box width={'100%'} height={'110px'}></Box>
                </div>
            </InfiniteScroll>
        );
    }

}

const GoodsTypeTag = ({ type, text, isMobile, isTablet = false }) => {
    let url
    let color
    if (type === 1) {
        url = GoodsType1
        color = '#A268D7'
    }
    if (type === 2) {
        url = GoodsType2
        color = '#81C350'
    }
    if (type === 3) {
        url = GoodsType3
        color = '#FDA940'
    }
    let mobiSize = isTablet ? 'calc(100vw / 820 * 14)' : 'calc(100vw / 360 * 12)'
    let mobiMarginSize = isTablet ? 'calc(100vw / 820 * 5)' : 'calc(100vw / 360 * 5)'
    if (isMobile) {
        return (<div height={mobiSize}>
            <Flex direction='row'>
                <img src={url} alt="" style={{ height: mobiSize }} /><p style={{ lineHeight: mobiSize, fontSize: mobiSize, color: color, fontWeight: 600, marginLeft: mobiMarginSize }}>{text}</p>
            </Flex>
        </div>
        )
    } else {
        return (<div height={'calc(max(57vw, 729.6px) / 1100 * 14)'}>
            <Flex direction='row'>
                <img src={url} alt="" style={{ height: 'calc(max(57vw, 729.6px) / 1100 * 14)' }} /><p style={{ lineHeight: 'calc(max(57vw, 729.6px) / 1100 * 14)', fontSize: 'calc(max(57vw, 729.6px) / 1100 * 14)', color: color, fontWeight: 600, marginLeft: 'calc(max(57vw, 729.6px) / 1100 * 5)' }}>{text}</p>
            </Flex>
        </div>)
    }

}

const GoodsCardMobi = ({ item, isMobile, isTablet = false }) => {

    let infoArr = item.goods_info
    let headImage = Image_BASE_URL + item.imgs[0]
    let circelName = infoArr.head[1]
    let voiceName = ''
    let goodsTypeText = ''
    infoArr.details.forEach((info) => {
        if (info.title === '声优') {
            voiceName = info.detail[0]
        }
        if (info.title === '作品类型') {
            goodsTypeText = info.detail[0]
        }
    });

    let mobiBoxStyle = { width: 'calc(100vw / 360 * 159)', height: 'calc(100vw / 360 * 240)', minHeight: 'calc(100vw / 360 * 240)' };
    if (isTablet) mobiBoxStyle = { width: 'calc(100vw / 820 * 236)', height: 'calc(100vw / 820 * 319)', minHeight: 'calc(100vw / 820 * 319)' };
    let imgStyle = { width: 'calc(100vw / 360 * 159)', height: 'calc(100vw / 360 * 119)' };
    if (isTablet) imgStyle = { width: 'calc(100vw / 820 * 236)', height: 'calc(100vw / 820 * 154)' };
    let underBoxStyle = { width: 'calc(100vw / 360 * 159)', height: 'calc(100vw / 360 * 121)', padding: 'calc(100vw / 360 * 12)' }
    if (isTablet) underBoxStyle = { width: 'calc(100vw / 820 * 236)', height: 'calc(100vw / 820 * 165)', padding: 'calc(100vw / 820 * 16)' }

    if (isMobile) {
        return <div>
            <Box
                style={mobiBoxStyle}
                direction='column'
                overflow='hidden'
                variant='subtle'
                borderRadius={'8px'}
                boxShadow='0 20px 20px rgba(0, 0, 0, 0.1)'
            >

                <Stack direction="column" spacing={0}>
                    <Box style={imgStyle}>
                        <img src={headImage} alt=""
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>
                    <Box style={underBoxStyle}>
                        <GoodsTypeTag type={item.goods_type} text={goodsTypeText} isMobile={true} isTablet={isTablet} />
                        <Text className={isTablet ? 'card-title-tb' : 'card-title-mobi'}>{item.title}</Text>
                        {voiceName.length > 0 ? <Text className={isTablet ? 'card-circel-info-tb' : 'card-circel-info-mobi'}>{circelName}{'/'}{voiceName}</Text> : <Text className={isTablet ? 'card-circel-info-tb' : 'card-circel-info-mobi'}>{circelName}</Text>}
                    </Box>
                </Stack>
            </Box>
        </div>
    } else {
        return <motion.div whileHover={{ opacity: 0.6 }}
        >
            <Box
                style={{ width: 'calc(max(57vw, 729.6px) / 1100 * 255)', height: 'calc(max(57vw, 729.6px) / 1100 * 346)' }}
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='subtle'
                borderRadius={'8px'}
                boxShadow='0 20px 20px rgba(0, 0, 0, 0.1)'
            >

                <Stack direction="column" spacing={0}>
                    <Box width="calc(max(57vw, 729.6px) / 1100 * 255)" height="calc(max(57vw, 729.6px) / 1100 * 191)">
                        <img src={headImage} alt=""
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>
                    <Box padding={'calc(max(57vw, 729.6px) / 1100 * 16)'} width="calc(max(57vw, 729.6px) / 1100 * 255)" height="calc(max(100vw, 1280px) / 360 * 155)">
                        <GoodsTypeTag type={item.goods_type} text={goodsTypeText} isMobile={false} />
                        <Text className='card-title'>{item.title}</Text>
                        {voiceName.length > 0 ? <Text className='card-circel-info'>{circelName}{'/'}{voiceName}</Text> : <Text className='card-circel-info'>{circelName}</Text>}
                    </Box>

                </Stack>
            </Box>
        </motion.div>
    }

}

export default ListPage;