// Footer.js
import React from 'react';
//import Twitter from '../assets/images/twitter.png'
import logoBottom from '../assets/images/logoBottom.png'
import logoBottom1 from '../assets/images/logo_1color.svg'
import { Box,Text } from '@chakra-ui/react';

const Footer = (isMobile) => {
  return (
    <div style={{ backgroundColor: '#F3F2F6', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', fontSize: '5px' }}>

      {/*       <a href=""> 
        <img src={Twitter} alt="Clickable Image" style={{width:'23px',height:'23px',marginTop:'20px'}}/>
      </a> */}

      <img src={logoBottom1} alt="Logo" style={{ marginTop: '20px', height: '26px', width: 'auto' }} />

      <Text mt="12px" textAlign="center" color="#929BBA" fontSize="10px">
        ＠乐游绘世 2023
      </Text>
      <Box width={'100%'} height={'20px'}></Box>
    </div>
  );
}

export default Footer;