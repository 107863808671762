import { extendTheme } from "@chakra-ui/react";
//import { mode } from "@chakra-ui/theme-tools";

const colorStr = "#4599e7"//"#f3af67"

const theme = extendTheme({
  colors: {
    primary: {
      500: colorStr, // 设置你想要的主题色值
    },
    secondry: {
        500: '#fcefc9'
    },
    check: {
      500: "#f6d356", 
      600: "#000000",
    },
    linkBg: {
      500: "#FDD132", 
    }
  },
});

export default theme;
