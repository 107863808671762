import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from "../../utils/constants";
import { Button, ButtonGroup, Flex, Image, Box, CircularProgress, CheckboxGroup, Stack, Checkbox } from '@chakra-ui/react';
import ScrollContainer from 'react-indiana-drag-scroll';
import SelectRight from '../../assets/images/selectRight.png';
import SubTitle from '../../assets/images/subHeader2_1.png';

const HomeTagBarMobi = ({ isMobile, isTablet = false,feedBack }) => {

    const [data, setData] = useState(null);
    const [selectedValues, setSelectedValues] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                //console.log('tag');
                const response = await axios.get(`${API_BASE_URL}/all_ages/public/index.php/taglist?type=0`);
                //console.log(response.data);
                if (response.data.code === 200) {
                    const items = response.data.data;
                    var array = [];
                    items.forEach(element => {
                        var dic = {
                            id: element.id,
                            name: element.name,
                        }
                        array.push(dic);
                    });
                }
                setData(array);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    if (!data) {
        return <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
            }}
        >
            <CircularProgress color="primary.500" size="30px" isIndeterminate />
        </div>;
    }

    const handleButtonClick = (value) => {
        var lastV
        if (selectedValues.includes(value)) {
            lastV = selectedValues.filter((val) => val !== value)
            setSelectedValues(lastV);
        } else {
            lastV = [...selectedValues, value]
            setSelectedValues(lastV);
        }
        feedBack(lastV)
    };
    const iconStyle = {
        width: '20px',
        height: '20px',
    }

    const handleCheckboxChange = (values) => {
        feedBack(values);
    };

    if (isMobile) {
        return (<Flex flexDirection="column" spacing={0} marginTop={isTablet ? '28px' : '20px'}>
            <Flex flexDirection="row" marginLeft={isTablet ? 'calc(100vw / 820 * 27)' : '14px'}>
                <Image src={SubTitle} w='21px' /><p style={{ lineHeight: '21px', fontSize: '16px', fontWeight: 'bolder', marginLeft: '6px' }}>PICK UP !</p>
            </Flex>
            <Box marginTop={isTablet ? '20px' : '8px'} maxW='100%'>
                <ScrollContainer horizontal={true} style={{ overflowX: 'auto' }}>
                    <ButtonGroup spacing="10px">
                        {data.map((item, index) => (
                            <Button
                                marginLeft={isTablet ? (index === 0 ? 'calc(100vw / 820 * 27)' : '20px') : '14px'}
                                paddingLeft="21px"
                                paddingRight="21px"
                                key={index}
                                fontSize="14px"
                                h="42px"
                                flex="1"
                                leftIcon={selectedValues.includes(item.id) ? <img src={SelectRight} style={iconStyle} alt='' /> : null}
                                colorScheme={selectedValues.includes(item.id) ? 'primary' : 'gray'}
                                onClick={() => handleButtonClick(item.id)}
                                borderRadius="21px"
                                minW="auto"
                                boxShadow={selectedValues.includes(item.id) ? "0 0 6px rgba(69, 153, 231, 0.4)" : "none"}
                            >
                                {item.name}
                            </Button>
                        ))}
                    </ButtonGroup>
                </ScrollContainer>

            </Box>
        </Flex>
        );
    } else {
        return (
            <div style={{ marginTop: "32px" }}>
                <Flex flexDirection="row" >
                    <Image src={SubTitle} w='21px' /><p style={{ lineHeight: '21px', fontSize: '18px', fontWeight: 'bolder', marginLeft: '6px' }}>PICK UP !</p>
                </Flex>
                <CheckboxGroup colorScheme="primary" onChange={handleCheckboxChange}>
                    <Stack spacing='12px' direction="column" marginTop='20px' marginLeft={1}>
                        {data.map((item, index) => (
                            <Checkbox key={index} iconColor="check.500" value={String(item.id)} fontSize={'16px'} _hover={{ color: 'primary.500' }}>{item.name}</Checkbox>
                        ))}
                    </Stack>
                </CheckboxGroup>
            </div>
        );
    }
}

export default HomeTagBarMobi;