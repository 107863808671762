import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
//import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import store from './utils/store';
import theme from "./utils/theme";

createRoot(document.getElementById('root')).render(

  //<React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  //</React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <ChakraProvider theme={theme}>
//       <App />
//     </ChakraProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();