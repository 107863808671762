// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from './pages/Home/Home';
// import Goods from './pages/Goods/Goods';
// import './App.css';

// const App = () => {

//   const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
//   let containerStyle = 'container'
//   if(isMobileDevice) containerStyle = 'container-mobi'
//   return (
//     <Router basename='/ag'>
//       <div className={containerStyle}>
//         <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/goods/:id" element={<Goods />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;


//import React, { useEffect, useState } from 'react';
import { Routes, Route ,HashRouter } from 'react-router-dom';
import Home from './pages/Home/Home';
import Goods from './pages/Goods/Goods';
import './App.css';

const App = () => {

  // const [isMobileDevice, setIsMobileDevice] = useState(false);
  // const [containerStyle, setContainerStyle] = useState('container');
  // useEffect(() => {
  //   const checkDeviceType = () => {
  //     const isMobile = /Mobi/i.test(window.navigator.userAgent);
  //     setIsMobileDevice(isMobile);
  //     setContainerStyle(isMobile ? 'container-mobi' : 'container');
  //   };

  //   checkDeviceType();

  //   window.addEventListener('resize', checkDeviceType);

  //   return () => {
  //     window.removeEventListener('resize', checkDeviceType);
  //   };
  // }, []);

  // return (
  //   <Router basename={Root_URL}>
  //     <div className={containerStyle}>
  //       <Routes>
  //         <Route path="/" element={<Home />}>
  //           <Route path="/goods/:id" element={<Goods />} />
  //         </Route>
  //       </Routes>
  //     </div>
  //   </Router>
  // );
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="goods/:id" element={<Goods />} />
        </Route>
      </Routes>
    </HashRouter>
  );

}

export default App;



