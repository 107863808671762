import { makeObservable, observable, action } from 'mobx';

class MyStore {
  data = null;

  constructor() {
    makeObservable(this, {
      data: observable,
      setData: action,
    });
  }

  setData(data) {
    this.data = data;
  }
}

const store = new MyStore();
export default store;