
import React, { useState } from 'react';
import { Button, ButtonGroup, Flex, Image, Box, Stack } from '@chakra-ui/react'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import ScrollContainer from 'react-indiana-drag-scroll';
import SelectRight from '../../assets/images/selectRight.png';
import SubTitle from '../../assets/images/subHeader1_1.png';

const ClassificationBarMobi = ({ isMobile, isTablet = false, feedBack }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const handleButtonClick = (value) => {
        var lastV
        if (selectedValues.includes(value)) {
            lastV = selectedValues.filter((val) => val !== value)
            setSelectedValues(lastV);
        } else {
            lastV = [...selectedValues, value]
            setSelectedValues(lastV);
        }
        feedBack(lastV);
    };
    const iconStyle = {
        width: '20px',
        height: '20px',
    }

    const handleCheckboxChange = (values) => {
        //console.log("选中的值：", values);
        feedBack(values);
    };

    if (isMobile) {
        return (<Flex flexDirection="column" spacing={0} marginTop={isTablet ? '28px' : '20px'}>
            <Flex flexDirection="row" marginLeft={isTablet ? 'calc(100vw / 820 * 27)' : '14px'}>
                <Image src={SubTitle} w='21px' /><p style={{ lineHeight: '21px', fontSize: '16px', fontWeight: 'bolder', marginLeft: '6px' }}>作品形式</p>
            </Flex>
            <Box marginTop={isTablet ? '20px' : '8px'}>
                <ScrollContainer horizontal={true} style={{ overflowX: 'auto' }}>
                    <ButtonGroup>
                        <Button
                            //_hover={{ boxShadow: "none" }}
                            marginLeft={isTablet ? 'calc(100vw / 820 * 27)' : '14px'}
                            paddingLeft="21px"
                            paddingRight="21px"
                            fontSize="14px"
                            h="42px"
                            flex="1"
                            leftIcon={selectedValues.includes(1) ? <img src={SelectRight} style={iconStyle} alt='' /> : null}
                            colorScheme={selectedValues.includes(1) ? 'primary' : 'gray'}
                            onClick={() => handleButtonClick(1)}
                            borderRadius="21px"
                            minW="auto"
                            boxShadow={selectedValues.includes(1) ? "0 0 6px rgba(69, 153, 231, 0.4)" : "none"}
                        >
                            游戏
                        </Button>
                        <Button
                            //_hover={{ boxShadow: "none" }}
                            marginLeft={isTablet ? '20px' : '14px'}
                            paddingLeft="21px"
                            paddingRight="21px"
                            fontSize="14px"
                            h="42px"
                            flex="1"
                            leftIcon={selectedValues.includes(2) ? <img src={SelectRight} style={iconStyle} alt='' /> : null}
                            colorScheme={selectedValues.includes(2) ? 'primary' : 'gray'}
                            onClick={() => handleButtonClick(2)}
                            borderRadius="21px"
                            minW="auto"
                            boxShadow={selectedValues.includes(2) ? "0 0 6px rgba(69, 153, 231, 0.4)" : "none"}
                        >
                            漫画
                        </Button>
                        <Button
                            //_hover={{ boxShadow: "none" }}
                            marginLeft={isTablet ? '20px' : '14px'}
                            paddingLeft="21px"
                            paddingRight="21px"
                            fontSize="14px"
                            h="42px"
                            flex="1"
                            leftIcon={selectedValues.includes(3) ? <img src={SelectRight} style={iconStyle} alt='' /> : null}
                            colorScheme={selectedValues.includes(3) ? 'primary' : 'gray'}
                            onClick={() => handleButtonClick(3)}
                            borderRadius="21px"
                            minW="auto"
                            boxShadow={selectedValues.includes(3) ? "0 0 6px rgba(69, 153, 231, 0.4)" : "none"}
                        >
                            音声・ASMR
                        </Button>
                    </ButtonGroup>
                </ScrollContainer>
            </Box>
        </Flex>

        );
    } else {
        return (
            <div style={{ marginTop: "32px" }}>
                <Flex flexDirection="row" >
                    <Image src={SubTitle} w='21px' /><p style={{ lineHeight: '21px', fontSize: '18px', fontWeight: 'bolder', marginLeft: '6px' }}>作品形式</p>
                </Flex>
                <CheckboxGroup colorScheme="primary" onChange={handleCheckboxChange}>
                    <Stack spacing={'12px'} direction="column" marginTop={'20px'} marginLeft={1}>
                        <Checkbox iconColor="check.500" value="1" fontSize={'16px'} _hover={{ color: 'primary.500' }}>游戏</Checkbox>
                        <Checkbox iconColor="check.500" value="2" fontSize={'16px'} _hover={{ color: 'primary.500' }}>漫画</Checkbox>
                        <Checkbox iconColor="check.500" value="3" fontSize={'16px'} _hover={{ color: 'primary.500' }}>音声・ASMR</Checkbox>
                    </Stack>
                </CheckboxGroup>
            </div>
        );
    }
}

export default ClassificationBarMobi;